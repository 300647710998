import { Injectable } from '@angular/core'
import * as msal from '@azure/msal-browser'
import { B2CConfig, UserInfo } from '../api/model/backend.model'
import { Subject, filter, interval, takeUntil, takeWhile } from 'rxjs'
import { Router } from '@angular/router'
import { AuthenticationResult } from '@azure/msal-browser'
import { IdentifySuccess } from '../store/actions/auth.actions'
import { Store } from '@ngrx/store'
import { AppState } from '../store/reducers'
import { MatDialog } from '@angular/material/dialog'
import { NewUserDisclaimerDialogComponent } from '../login/new-user-disclaimer-dialog.component'
import { BackendService } from '../api/backend.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
    private msal: msal.PublicClientApplication | null = null
    private b2cConfig: B2CConfig | null = null
    private userInfo: UserInfo | null = null
    private username: string | null = null
    private handledAfterLoginRedirect = false
    private accessToken = ''

    account: msal.AccountInfo | null = null
    accessTokenRefreshed = new Subject<string>()

    constructor(
        private router: Router,
        private store: Store<AppState>,
        public dialog: MatDialog,
        private backendService: BackendService,
        // private _snackBar: MatSnackBar
    ) {}

    async appSignIn(username: string, force: boolean) {
        this.username = username

        if (this.username && this.username.trim()) {
            const normalizedEmail = this.username.trim().toLowerCase()
            sessionStorage.setItem('username', normalizedEmail)
            console.log('%c signIn 1 ', 'background: grey; color: white', this.username)
            await this.initMsalForLogin(normalizedEmail, force)
        } else if (force) {
            alert('username is required')
        }
    }

  async trySignIn(username: string, force: boolean) {
    this.username = username

    if (this.username && this.username.trim()) {
      const normalizedEmail = this.username.trim().toLowerCase()
      sessionStorage.setItem('username', normalizedEmail)
      await this.initMsalForLogin(normalizedEmail, force)
    } else if (force) {
      alert('username is required')
    }
  }

  async handleAfterLoginRedirect(username: string) {
        this.username = username
        this.handledAfterLoginRedirect = true
        await this.initMsalForLogin(this.username, false)
    }

    private async initMsalForLogin(email: string, forceLogin: boolean) {
        this.b2cConfig = (
            await this.backendService.getAzureB2CConfig(email, false).toPromise()
        ).data!
        await this.initMsal(this.b2cConfig!.policyName)
        const response = await this.msal!.handleRedirectPromise()

        if (response) {
          this.handledAfterLoginRedirect = true
          console.log('%c RESPONSE: YES! ', 'background: green; color: white', response)
          localStorage.setItem('checkpoint2', String(response.account));
          await this.setAccount(response.account!)
        }

        if (this.account == null) {
          // console.log('%c REFRESH! ', 'background: green; color: white')
          // in case of page refresh
          await this.selectAccount()
        }

        if (this.account) {
          if (this.userInfo && this.userInfo.approval_timestamp == null) {
            // console.log('%c signIn 4b Account! ', 'background: red; color: white', this.userInfo.approval_timestamp)
            const result = await this.dialog
            .open(NewUserDisclaimerDialogComponent, {
              data: this.userInfo.eulaContent,
              disableClose: true,
            })
            .afterClosed()
            .toPromise()
            // console.log('%c signIn 4b Account! ', 'background: red; color: white', result)
            localStorage.setItem('checkpoint3', String(this.userInfo.eulaContent));
            if (result === 'agree') {
              const currentDate = Date().toString().slice(0, 25)
              // tslint:disable-next-line
              const response = <any>(
                await this.backendService
                .putUserTimeStamp(this.userInfo.id, '"' + currentDate + '"')
                .toPromise()
              )
              if (response.error) {
                // this._snackBar.open('Error Editing User login timestamp.', 'X', {
                //   duration: 2000,
                // })
              } else {
                this.store.dispatch(
                  IdentifySuccess({
                    username: this.account.username,
                    redirectURL: window.location.origin,
                    name: `${this.account.idTokenClaims!['given_name']} ${this.account.idTokenClaims!['family_name']}`,
                    token: this.accessToken,
                  })
                )
              }
            }
          } else {
            console.log('%c signIn Auth Token ', 'background: darkcyan; color: white')
            console.log(this.accessToken)
            localStorage.setItem('checkpoint3', `${this.account.idTokenClaims!['given_name']} ${this.account.idTokenClaims!['family_name']}`);
            localStorage.setItem('checkpoint3b', String(this.accessToken));
            this.store.dispatch(
              IdentifySuccess({
                username: this.account.username,
                redirectURL: window.location.origin,
                name: `${this.account.idTokenClaims!['given_name']} ${this.account.idTokenClaims!['family_name']}`,
                token: this.accessToken,
              })
            )
          }
          if (!this.router.url.includes('intro')){
            await this.router.navigate(['/login/enter-chat'])
          }
        }
        else if (forceLogin) {
          console.log('%c RESPONSE: NO!, forceLogin ', 'background: darkred; color: white', this.b2cConfig!.clientId, this.b2cConfig)
          await this.msal!.loginRedirect({
            scopes: [this.b2cConfig!.clientId],
            loginHint: email ?? undefined,
          })
        }
    }

    async redirectToPasswordReset() {
        // tslint:disable-next-line: no-non-null-assertion
        this.b2cConfig = (
            await this.backendService.getAzureB2CConfig('', true).toPromise()
        ).data!
        // tslint:disable-next-line: no-non-null-assertion
        await this.initMsal(this.b2cConfig!.policyName)
        await this.selectAccount()
        // tslint:disable-next-line: no-non-null-assertion
        await this.msal!.loginRedirect({ scopes: [this.b2cConfig!.clientId] })
    }

    private async initMsal(policyName: string) {
      localStorage.setItem('checkpoint7', this.b2cConfig!.clientId);
      this.msal = new msal.PublicClientApplication({
            auth: {
                // tslint:disable-next-line: no-non-null-assertion
                authority: `${this.b2cConfig!.instance}/${
                    // tslint:disable-next-line: no-non-null-assertion
                    this.b2cConfig!.domain
                }/${policyName}`,
                // tslint:disable-next-line: no-non-null-assertion
                clientId: this.b2cConfig!.clientId,
                // tslint:disable-next-line: no-non-null-assertion
                knownAuthorities: [this.b2cConfig!.knownAuthority],
                redirectUri: window.location.origin,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
        })
      localStorage.setItem('checkpoint4ClientId', String(this.b2cConfig!.clientId));
      await this.msal.initialize()
    }

    private async selectAccount(): Promise<void> {
        // tslint:disable-next-line: no-non-null-assertion
        const currentAccounts = this.msal!.getAllAccounts()
        // console.log('%c signIn 6 ', 'background: dodgerblue; color: white', currentAccounts)

        if (currentAccounts.length < 1) {
            return
        } else if (currentAccounts.length > 1) {
            // Due to the way MSAL caches account objects, the auth response from initiating a user-flow is cached as a new account, which results in more than one account in the cache. Here we make sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow, as this is the default flow the user initially signed-in with.
          const accounts = currentAccounts.filter(
                account =>
                    account.homeAccountId
                        .toUpperCase()
                        // tslint:disable-next-line: no-non-null-assertion
                        .includes(this.b2cConfig!.policyName.toUpperCase()) &&
                    account.idTokenClaims &&
                    account.idTokenClaims.iss &&
                    account.idTokenClaims.iss
                        .toUpperCase()
                        // tslint:disable-next-line: no-non-null-assertion
                        .includes(this.b2cConfig!.knownAuthority.toUpperCase()) &&
                    // tslint:disable-next-line: no-non-null-assertion
                    account.idTokenClaims.aud === this.b2cConfig!.clientId
            )

            if (accounts.length > 1) {
                // localAccountId identifies the entity for which the token asserts information.
                if (
                    accounts.every(
                        account => account.localAccountId === accounts[0].localAccountId
                    )
                ) {
                    // All accounts belong to the same user
                    await this.setAccount(accounts[0])
                } else {
                    // Multiple users detected. Logout all to be safe.
                    this.signOut()
                }
            } else if (accounts.length === 1) {
                await this.setAccount(accounts[0])
            }
        } else if (currentAccounts.length === 1) {
            await this.setAccount(currentAccounts[0])
        }
    }

    private async setAccount(account: msal.AccountInfo): Promise<void> {
        const navigateToEnterChat =
            this.account === null &&
            account !== null &&
            this.handledAfterLoginRedirect
        console.log('%c setAccount 1 ', 'background:yellow; color: white', navigateToEnterChat)
        this.account = account
        this.username = account.username
        sessionStorage.setItem('username', account.username) // in case user came from password reset flow
        localStorage.setItem('username', account.username);
        await this.getAccessToken()

        if (navigateToEnterChat) {
            this.router.navigate(['/login/enter-chat'])
        }
        const oneMinute = 1000 * 60
        const oneHour = oneMinute * 60

        interval(oneHour - oneMinute).pipe(
          takeWhile(() => this.account !== null),
        ).subscribe(() => {
            this.getAccessToken(true)
        })
    }

    async getAccessToken(forceRefresh: boolean = false): Promise<string | null> {
        if (this.account === null) {
            return null
        }

        // tslint:disable-next-line: no-non-null-assertion
        const account = this.msal!.getAccountByHomeId(this.account.homeAccountId)!

        const req = {
            account,
            // tslint:disable-next-line: no-non-null-assertion
            scopes: [this.b2cConfig!.clientId],
            forceRefresh,
        }

        let response: AuthenticationResult | null = null

        try {
            // tslint:disable-next-line: no-non-null-assertion
            response = await this.msal!.acquireTokenSilent(req)
        } catch (error) {
            console.log('Silent access token acquisition failed.')
            console.error(error)
        }

        if (response === null || !response.accessToken || response.accessToken === '') {
            console.log('Acquiring access token using redirect...')
            // tslint:disable-next-line: no-non-null-assertion
            await this.msal!.acquireTokenRedirect(req)
            return ''
        } else {
            const hasChanged = this.accessToken !== response.accessToken

            if (hasChanged) {
                this.accessToken = response.accessToken
                this.accessTokenRefreshed.next(this.accessToken)
            }
            localStorage.setItem('checkpoint6', String(this.accessToken));
            return this.accessToken
        }
    }

  async signOut() {
    this.account = null
    this.username = null
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('msal.interaction.status')
    const loginCheck =  localStorage.getItem('checkpoint4ClientId');
    localStorage.removeItem('checkpoint4ClientId')
    console.log('%c signOut 2 ', 'background:yellow; color: white', loginCheck)

    if (this.msal) {
      await this.msal.logoutRedirect({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      })
      this.rerouteToLogin().then(r => r)
    } else {
      console.log(
        'An attempt was made to log out while MSAL was not initialized!'
      )
    }
    this.rerouteToLogin().then(r => r)
  }

  async rerouteToLogin(): Promise<void> {
    await this.router.navigate(['/login'])
  }
}
