<div class="mock-select-menu" *ngIf="showPopover">
  <span *ngIf="chatTypesArray">
    <div class="mock-selection" *ngFor="let ct of chatTypesArray; let i = index"
         (click)="setChatType(ct)" [ngClass]="{'selected': chatType === ct}">
      {{ct}}
    </div>
  </span>
</div>
<ion-header [translucent]="true" xmlns="http://www.w3.org/1999/html">
  <ion-toolbar>
    <!-- <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons> -->
    <ion-title>

      <div class="sage-title">
        <div class="selector" (click)="handleChange($event)">
          <div>
            {{chatType}}
          </div>
          <img src="assets/icons/select-arrow.png" alt="select arrow">
        </div>
      </div>

    </ion-title>
  </ion-toolbar>
</ion-header>


<div class="sage-chat-background" *ngIf="chat.length === 0">
  <img src="assets/logos/sage-logo.png" alt="sage">
</div>

<ion-content #scrollElement scrollX="true" scrollY="true">


  <ion-list>

    <div class="loading-spinner-ellipsis" *ngIf="!canAsk">
      <div class="loading-animation">
        <div></div><div></div><div></div><div></div><div></div>
      </div>
    </div>

    <div class="le-chat" #listElement *ngFor="let c of chat">
      <div class="text-balloon" [ngClass]="{'me': c.source === 'app'}" [innerHTML]="c.chatText | markdown">
      </div>
      <div class="chart-image" *ngIf="c.img">
        <img [src]="c.img" alt="{{ c.chatText }}" (click)="openImage({src: c.img, chatText: c.chatText})">
      </div>
    </div>

  </ion-list>

</ion-content>

<div class="input-container">
  <div class="chat-suggestions" [style.display]="showSuggestions ? 'block' : 'none'">
<!--    <div class="suggestion" *ngFor="let s of suggested; let i = index" (click)="clickSuggestion(i)">{{s}}</div>-->
    <swiper-container
      fmSwiper
      [config]="config"
      (slidechange)="swiperSlideChanged($event)"
    >
      <swiper-slide *ngFor="let s of suggestions; let i = index" (click)="clickSuggestion(i)">
        <div class="swiper-text">
          <p>{{s}}</p>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>

  <div class="suggestion-toggle" (click)="toggleSuggestion()">
    <mat-icon [fontIcon]="showSuggestions ? 'comments_disabled' : 'comment'" size="large"></mat-icon>
  </div>

  <textarea
      aria-label="ChatInput"
      name="chatInput"
      type="text"
      #inputElement
      (input)="handleChatChange()"
      [placeholder]="chatPlaceholder"
      [(ngModel)]="chatInput"
    ></textarea>
  <div class="send-button" [ngClass]="{'activated': chatInput.length > 0}" (click)="sendChatRequest()">
    <mat-icon fontIcon="arrow_upward"></mat-icon>
  </div>

</div>
