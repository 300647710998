import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import { ChatService, chatTypes } from "../services/chat.service"
import { Chat, ResponseModel, RequestModel } from "../models/chat.model"
import { select, Store } from '@ngrx/store'
import { AppState } from '../store/reducers'
import { selectAuthState, selectToken, selectUsername } from "../store/selectors/auth.selectors"
import { ActivatedRoute, Router } from "@angular/router"
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { IonContent, ModalController } from "@ionic/angular"
import { A11y, Mousewheel, Navigation, Pagination, SwiperOptions } from 'swiper'
import { ImageModalComponent } from './image-modal.ts/image-modal.component'
import { Dialog } from '@angular/cdk/dialog'
import { window } from 'd3'
import { filter, map, tap, withLatestFrom } from 'rxjs'

@Component({
  selector: 'app-chat',
  styleUrls: ['./chat.page.scss'],
  templateUrl: './chat.page.html',
})

export class ChatPage implements OnInit, AfterViewInit {
  chatType = 'Benchmarking'
  chatInput: string = ""
  chatTypesArray: string[]
  chatPlaceholder: string = "Ask SAGE"
  chat: Chat[] = []
  canAsk = true
  token: string
  username: string
  offsetHeight = 0
  offsetTop = 0
  heightCutoff = 375
  useFixed = false
  debug = 'John.Barker@perficient.com'
  suggestions: string[]
  showSuggestions = true
  showPopover = false
  fullscreenImage = false
  fullscreenImageSrc: string

  benchmarkingRequests: string[] = [
    "What was Progressive's personal auto premium and combined ratio in 2023?",
    "Create an underwriting summary of Chubb’s Other Liability Occurrence line.",
    'Provide a market share summary for Travelers Work Comp in CA.',
    'Provide the company summary for AIG.',
    'Create a bar chart of the top ten writers of OLO in 2023.',
    'Create a bar chart of State Farm\'s HO premium for the last 10 years.',
    'Create a performance plot for SECURA.',
    'List all available lines of business.',
  ]
  miRequests: string[] = [
    'Provide the MI infographic for Ren Re',
    'Provide the MI infographic for Partner Re',
    'Provide the MI infographic for Swiss Re',
  ]
  structuringRequests: string[] = [
    'Using the 2024 Retro curve, what is the price for a layer with a 3.24% EL?',
    'List all available pricing curves',
    'Compare the 2022, 2023, and 2024 Retro pricing curves.',
    'Summarize the 2024 NA Commercial pricing curve.',
    'Compare a layer with 3.24% EL and 15% ROL against the 2024 Retro curve.',
    'List all SAGE programs for Demo in 2024.',
    'List all SAGE structures for program id 2768.',
    'Provide the layer details for SAGE structure 78109.',
    'Compare the layers in structure 78114 against the 2024 NA Commercial pricing curve.',
    'Provide the portfolio details for SAGE structure 78109.',
    'Provide the tail metrics for SAGE structure 78109. OEP, WS, Loss+RP.',
    'Compare metrics for structures 78109, 78110, 78111, 78112.',
    'Get the metrics for program 2768 compare view Mobile Compare View 2.',
  ]

  public config: SwiperOptions = {
    spaceBetween: 10,
    slidesPerView: 2.4,
    centeredSlides: false,
    loop: true,
    freeMode: true,
    breakpoints: {
      1000: {
        slidesPerView: 3
      },
      1600: {
        slidesPerView: 4
      },
      2000: {
        slidesPerView: 5
      }
    }
  }

  // public config: SwiperOptions = {
  //   modules: [Navigation, Pagination, A11y, Mousewheel],
  //   autoHeight: true,
  //   spaceBetween: 20,
  //   navigation: false,
  //   pagination: {clickable: true, dynamicBullets: true},
  //   slidesPerView: 2,
  //   centeredSlides: true,
  //   breakpoints: {
  //     400: {
  //       slidesPerView: "auto",
  //       centeredSlides: false
  //     },
  //   }
  // }

  @ViewChild("scrollElement") content: IonContent
  @ViewChild("listElement") myIdent: ElementRef;
  @ViewChild("inputElement") iEl: ElementRef;

  constructor(
    private chatService: ChatService,
    private store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private modalController: ModalController,
    private dialog: Dialog
  ) {
    this.suggestions = this.benchmarkingRequests
    // this.route.params.subscribe((a) => {
      // In case these needed to be synced with the hamburger menu
      // console.log('%c ROUTE params ', 'background:darkblue; color:yellow;', a, this.chatType, chatType)
    // })
    this.store.pipe(
      withLatestFrom(this.store.select(selectAuthState)),
      map(([, authState]) => authState.username?.endsWith('lockton.com') && !authState.username.startsWith('borndigital'))
    ).subscribe(showMi => {
      let chatTypesArray = Object.keys(chatTypes).map((chatType) => {
        // @ts-ignore
        return chatTypes[chatType]
      })
      if (!showMi) {
        chatTypesArray = chatTypesArray.filter(x => !x.toLowerCase().includes('mi'))
      }
      this.chatTypesArray = chatTypesArray
    })
  }

  ngOnInit(): void {
    this.store.pipe(select(selectToken)).subscribe(a=> {
      this.token = a as string
      // this.checkAuth(this.token)
    })
    this.store.pipe(select(selectUsername)).subscribe(a=> {
      this.username = a as string
      if (!this.username) {
        this.username = this.debug
      }
      // console.log('%c Chat! Username: ', 'background: darkmagenta; color: white', this.username)
    })
    this.updateScroll()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // This is a hack to get around a bug in Safari's web-view where the navigation bar disappears and the body tag takes it place.
      // However only a projection of body tag takes it place.
      // The touch events and box size calculations are still rendered as if the navigation bar was there.

      globalThis.window.scrollTo(0, 1)
      console.log('%c Chat! ngAfterViewInit scroll function', 'background: darkmagenta; color: white')

    },2000)
  }

  async openImage($event: {src: SafeResourceUrl, chatText: string }) {
    const modal = await this.modalController.create({
      component: ImageModalComponent,
      componentProps: {
        imageUrl: $event.src,
        chatText: $event.chatText
      },
      cssClass: 'image-modal'
    })
    return await modal.present()
  }

  sendChatRequest(): void {
    localStorage.setItem('apiError', '')
    this.showSuggestions = false
    if (this.chatInput === 'a' || this.chatInput === 'A') {
      this.chatInput = this.benchmarkingRequests[0]
    }
    if (this.chatInput === 'b' || this.chatInput === 'B') {
      this.chatInput = this.benchmarkingRequests[1]
    }
    if (!this.canAsk || this.chatInput === '') {
      return
    }

    const chatText = this.chatInput.trim()
    // console.log('%c Chat! Post RSP: ', 'background: blue; color: white', this.chatInput)
    this.canAsk = false
    this.chat = [{chatText, id: 'me', img: false, source: 'app'}, ...this.chat]
    this.chatInput = ''
    this.updateScroll()
    const payload = this.getPayload(chatText)
    setTimeout(() => {
      const apiError = localStorage.getItem('apiError')
      console.log('%c chatPost TIMEOUT! 2 ', 'background: darkmagenta; color: dodgerblue', ' this.canAsk: ', this.canAsk, apiError)
      if (apiError === '401') {
        this.chatInput = ''
        this.canAsk = true
        // this.chat = []
        this.rerouteToLogin().then(r => r)
      }
    }, 3000)

    console.log('%c chatPost ', 'background: darkmagenta; color: dodgerblue', this.chatType, chatText)
    this.chatService.doChatPost(this.chatType, payload)
      .subscribe( (resp: ResponseModel ) => {
        console.log('%c chatPost Rsp Check ', 'background: magenta; color: white', resp)
        this.canAsk = true
        if (resp && resp.errorMessage == null) {
          const img = resp.output.image
            ? this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp.output.image)
            : false
          this.chat = [{chatText: `${resp.output.response}`, id: `${Math.ceil(Math.random() * 1000)}`,
            img, source: 'response'},
            ...this.chat]
          setTimeout(() => { // Wait for next frame to calculate height and do scrolling operations
            this.heightScrollCheck()
            this.updateScroll()
          })
        }
      })
      this.chatInput = ''
   }

  setChatType(chatType: string): void {
    this.chatType = chatType
    this.chatService.setChatTypeObs(chatType)
    this.switchSuggestions(chatType)
    setTimeout(() => {
      this.showPopover = !this.showPopover
    }, 400)
    console.log('%c chatType change ', 'background: darkred; color: white', this.chatType)
  }

  switchSuggestions(chatType: string): void {
    this.suggestions = this.benchmarkingRequests
    if (chatType === chatTypes.mi) {
      this.suggestions = this.miRequests
    } else if (chatType === chatTypes.structuring) {
      this.suggestions = this.structuringRequests
    }
  }

  heightScrollCheck(): void {
     if (this.myIdent && this.myIdent.nativeElement) {
       this.offsetHeight = this.myIdent.nativeElement.offsetHeight
       this.offsetTop = this.myIdent.nativeElement.offsetTop
       this.useFixed = false // this.offsetTop < this.heightCutoff
       console.log('%c Chat! HEIGHT Scroll: ', 'background: blue; color: white', this.offsetHeight, ' or ', this.offsetTop, " < 475 ?", this.useFixed)
     }
   }

  handleChatChange(): void {
    const inpElHeight = this.iEl.nativeElement.offsetHeight
    // console.log('%c inpEl ', 'background: magenta; color: white', this.iEl)
  }
  clickSuggestion(ind: number): void {
    const inquiry = this.suggestions[ind]
    this.showSuggestions = false
    // console.log('%c clickSuggestion ', 'background: darkblue; color: white', inquiry)
    this.chatInput = inquiry
    this.iEl.nativeElement.focus()
    // this.sendChatRequest()
  }

  getPayload(request: string): RequestModel {
    return {
      input: {
        request
      },
      config: {},
      kwargs: {}
    }
  }

  // checkAuth(token: string): void {
  //   if (!token) {
  //     const username = sessionStorage.getItem('username')
  //     if (!username) {
  //       this.rerouteToLogin().then(r => r)
  //     } else {
  //       this.authService.appSignIn(username as string, true).then(r => {})
  //     }
  //   }
  // }

  async rerouteToLogin(): Promise<void> {
    console.log('%c REROUTE@chat ', 'background:indigo; color: white')
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('msal.interaction.status')
    localStorage.removeItem('checkpoint4ClientId')
    await this.router.navigate(['/login'])
  }

  updateScroll(): void {
    if (this.content && this.content.scrollToBottom) {
      this.content.scrollToBottom(300)
    }
  }

  toggleSuggestion(): void {
    this.showSuggestions = !this.showSuggestions
    // console.log('%c Chat! TOGGLE!: ', 'background: blue; color: white', this.showSuggestions)
  }

  handleChange(event: Event): void {
    this.showPopover = !this.showPopover
  }

  swiperSlideChanged(e: any): void {
    // console.log('%c swipe: ', 'background: blue; color: white', e)
  }

  protected readonly event = event;
}
