import { Component, OnInit } from '@angular/core'
import { register } from 'swiper/element/bundle'
import { BnNgIdleService } from 'bn-ng-idle'
import { AuthService } from './services/auth.service'
import { Router } from '@angular/router'
import { ChatService } from './services/chat.service'
import { environment } from '../environments/environment'
import { Observable, Subscription } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { selectShowMenu } from './store/selectors/menu.selectors'
import { AppState } from './store/reducers'
import { updateShowMenu } from './store/actions/menu.actions'
import { updateAuthToken } from './store/actions/auth.actions'

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Chat', url: '/chat', icon: 'chatbubbles' },
  ]
  authServiceSubscription: Subscription
  dialogOpen: boolean = false
  chatType: string
  showMenuState$: Observable<boolean>
  showMenu: boolean = false
  showHamburger = false
  showMenuSubscription: Subscription
  tempTimer = environment.timeout
  constructor(
    private idleService: BnNgIdleService,
    private authService: AuthService,
    private router: Router,
    private chatService: ChatService,
    private store: Store<AppState>
  ) {
    this.chatService.getChatTypeObs().subscribe((a: string) => this.chatType = a)
    this.showMenuState$ = this.store.pipe(select(selectShowMenu))
    this.authServiceSubscription =
      this.authService.accessTokenRefreshed.subscribe(token => {
        this.store.dispatch(updateAuthToken({ token }))
      })

    const previousUrlLastIndexOf = window.location.pathname
      .toString()
      .substr(window.location.pathname.toString().lastIndexOf('/') + 1)
    if (
      !previousUrlLastIndexOf.includes('login') &&
      !previousUrlLastIndexOf.includes('access_token') &&
      previousUrlLastIndexOf.length > 0
    ) {
      // console.log('%c APP login 1 ', 'background:yellow; color: white', previousUrlLastIndexOf)
      sessionStorage.setItem('reDirectUrl', window.location.pathname)
    }

    const username = sessionStorage.getItem('username')
    const isAfterLoginRedirect = window.location.hash && window.location.hash.startsWith('#state=')

    if (isAfterLoginRedirect) {
      console.log('%c APP after redirect 2 ', 'background:green; color: white', username)
      this.authService.handleAfterLoginRedirect(String(username)).then(r => r)
    } else if (username) {
      this.authService.trySignIn(username, false).then(() => {
        if (this.authService.account === null) {
          this.router.navigate(['/login']).then(r => r)
        }
      })
    } else {
      if (!window.location.href.includes('intro')) {
        this.router.navigate(['/login']).then(r => r)
      }
    }
  }

  setChatType(chatType: string): void {
    this.chatService.setChatTypeObs(chatType)
  }

  ngOnInit(): void {
    this.showMenuSubscription = this.showMenuState$.subscribe(state => this.showMenu = state)
    this.idleService
      .startWatching(this.tempTimer)  // environment.timeout
      .subscribe(async (isTimedOut: boolean) => {
        const token = await this.authService.getAccessToken()
        if (isTimedOut && !this.dialogOpen && token) {
          this.dialogOpen = true
          console.log('%c TIMEOUT check : ', 'background: darkred; color: white', isTimedOut, token)
          await this.router.navigate(['/login'])

          // const dialogRef = this.dialog.open(TimeoutDialogComponent, {
          //   disableClose: true,
          // })
          // dialogRef.afterClosed().subscribe(async result => {
          //   this.dialogOpen = false
          //   if (result === 'Reset') {
          //     this.bnIdle.resetTimer()
          //   } else {
          //     await this.authService.signOut()
          //   }
          // })
        }
      })
      this.router.events.subscribe(() => {
        this.showHamburger = this.router.url.includes('chat')
      })
  }

  ngOnDestroy(): void {
    this.showMenuSubscription.unsubscribe()
  }

  hamburgerClick(): void {
    const showMenu = true
    this.store.dispatch(updateShowMenu({showMenu}))
  }
}
