import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, map, Observable, throwError } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "../store/reducers";
import { selectToken } from "../store/selectors/auth.selectors";
import { RequestModel, ResponseModel } from "../models/chat.model";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import {AuthService} from "./auth.service";
import { environment } from 'src/environments/environment';

export enum chatTypes {
  benchmarking = 'Benchmarking',
  mi = 'MI',
  structuring = 'Structuring'
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  token: string
  apiUrl2 = "/api/GptApi/InvokeSingle"
  apiBenchmark = "/api/GptApi/benchmark-agent"
  apiMI = "/api/GptApi/mi-agent"
  apiStructuring = "/api/GptApi/structuring-agent"
  chatTypeBuddy$ = new BehaviorSubject<string>(chatTypes.benchmarking);

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private auth: AuthService,
    private router: Router
  ) {
    this.store.pipe(select(selectToken)).subscribe(a => {
      this.token = a as string
    })
  }

  doChatPost(requestType: string, req: RequestModel): Observable<ResponseModel> {
    console.log('%c doChatPost ', 'background:indigo; color: white; font-size: 1rem', requestType, req)
    let devRoot = ''
    const currentHost = window.location.host
    if (currentHost.includes('dev') || currentHost.includes('local')) {
      devRoot = 'https://dev.mobile.sage.lockton.com'
    }
    let reqUrl = devRoot + this.apiBenchmark
    if (requestType === chatTypes.mi) {
      reqUrl = devRoot + this.apiMI
    } else if (requestType === chatTypes.structuring) {
      reqUrl = devRoot + this.apiStructuring
    }
    console.log("CHAT REQUEST URL",reqUrl)
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      })
    };
    return this.http.post(reqUrl, req, httpOptions)
      .pipe(
        map((rsp) => {
          console.log('%c POST ', 'background:indigo; color: white; font-size: 1rem', requestType, req, rsp)
          return rsp as ResponseModel
        }),
        catchError(this.handleError)
      )
  }

  protected handleError(error: HttpErrorResponse, ) {
    console.error('%c Error encountered 5:', 'background:red; color: white; font-size: 1rem', error)
    localStorage.setItem('apiError', String('401'));
    // this.router.navigate(['/login']).then(r => r)
    // this.rerouteToLogin().then(r => r)
    this.auth.signOut().then(r => r)
    // console.error('%c Error encountered 5:', 'background:red; color: white', error)
    if (error.status === 0) {
      console.error('An error occurred 3:', error.error)
    } else {
      this.auth.signOut().then(r => r)
      console.error(
        `Backend returned code ${error.status}, 3 body was: `, error.error
      )
    }
    this.rerouteToLogin().then(r => r)
    return throwError(() => 'Something bad happened; please try again later.')
  }

  async rerouteToLogin(): Promise<void> {
    // console.log('%c REROUTE 3 ', 'background:indigo; color: white')
    await this.router.navigate(['/login'])
  }

  getChatTypeObs(): Observable<string> {
    // console.log('%c ChatType ', 'background:orange; color: white', this.chatTypeBuddy$.asObservable())
    return this.chatTypeBuddy$.asObservable();
  }

  setChatTypeObs(chatType: string): void {
    // console.log('%c ChatType Set ', 'background:orange; color: white', chatType)
    this.chatTypeBuddy$.next(chatType);
  }
}
